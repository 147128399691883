


















































import FlsActivityForm from '@/components/flsActivity/FlsActivityForm.vue';
import FlsActivityUtils, { FlsActivity } from '@/utils/FlsActivityUtils';
import Vue from 'vue';

export default Vue.extend({
  components: { FlsActivityForm },

  data(): {
    state: {
      valid: boolean;
      submit: boolean;
      delete: boolean;
      errors?: any[];
    };
    item?: Partial<FlsActivity>;
  } {
    return {
      state: {
        valid: false,
        submit: false,
        delete: false,
        errors: undefined,
      },
      item: undefined,
    };
  },

  async mounted() {
    try {
      this.item = FlsActivityUtils.flsActivity.generateDefault(
        await FlsActivityUtils.api.get(parseInt(this.$route.params?.id))
      );
      (this.$refs.form as any).resetValidation();
    } catch (error: any) {
      console.error(error);
      if (error.response.status === 404) {
        // TODO: not found
      }
    }
  },

  methods: {
    async handleDelete() {
      try {
        this.state.errors = undefined;
        this.state.delete = true;
        await FlsActivityUtils.api.remove(this.item?.id as number);
        this.$toast.push({
          text: this.$t('success.delete', [this.$tc('fls_activity.label')]),
          type: 'success',
        });
        this.$router.replace({ name: 'fls-activity-list' });
      } catch (error: any) {
        this.$toast.push({
          text: this.$t('error.delete', [this.$tc('fls_activity.label')]),
          type: 'error',
        });
        this.state.errors = error; // TODO: handle errors
      } finally {
        this.state.delete = false;
      }
    },
  },
});
